import Vue from 'vue'
import Vuex from 'vuex'

import * as baseModules from './modules'
import { files } from './modules/files/files'

import router from '@/router'

import i18n, { mapNavigatorLanguage } from '@/i18n'
import vuetify, { mapI18nLocale } from '@/plugins/vuetify'

import { apiProjectResources } from '@/api'

const modules = {
  ...baseModules,
  globalFiles: files // 給選取檔案位置使用
}

Vue.use(Vuex)

const state = () => ({
  influxBucketId: '',
  s3BucketId: '',
  grafanaId: ''
})

const getters = {}

const actions = {
  init: ({ commit, dispatch }) => {
    commit('setState', state())

    Object.keys(modules).forEach(key => {
      dispatch(`${key}/init`)
    })
  },

  fetchResourceIds: ({ commit }) => {
    return apiProjectResources.get().then(res => {
      // res.data = {
      //   result: 'ok',
      //   data: [
      //     {
      //       uuid: String,
      //       resource_type: 'S3_BUCKET'
      //     },
      //     {
      //       uuid: String,
      //       resource_type: 'INFLUX_BUCKET'
      //     },
      //     {
      //       uuid: String,
      //       resource_type: 'GRAFANA_ROOT'
      //     },
      //     ...
      //   ]
      // }

      const resources = res?.data?.data
      if (!Array.isArray(resources)) return

      const S3_BUCKET = resources.find(
        resource => resource.resource_type === 'S3_BUCKET'
      )
      const INFLUX_BUCKET = resources.find(
        resource => resource.resource_type === 'INFLUX_BUCKET'
      )
      const GRAFANA_ROOT = resources.find(
        resource => resource.resource_type === 'GRAFANA_ROOT'
      )

      commit('setState', {
        influxBucketId: INFLUX_BUCKET?.uuid || '',
        s3BucketId: S3_BUCKET?.uuid || '',
        grafanaId: GRAFANA_ROOT?.uuid || ''
      })
    })
  },

  changeLanguage: (_, { lang } = {}) => {
    if (lang === i18n.locale) {
      return
    }

    // 要考慮人為任意輸入在導覽列
    const i18nLocale = mapNavigatorLanguage(lang)
    i18n.locale = i18nLocale
    vuetify.framework.lang.current = mapI18nLocale(i18nLocale)

    const route = router.currentRoute
    const routeLang = route.params.lang
    if (
      routeLang &&
      routeLang !== i18nLocale
    ) {
      router.replace({
        name: route.name,
        params: {
          ...route.params,
          lang: i18nLocale
        },
        query: route.query
      })
    }
  }
}
const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export default new Vuex.Store({
  modules,
  state,
  getters,
  actions,
  mutations
})
