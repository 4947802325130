import i18n from '@/i18n'
import { apiProjectProjects } from '@/api'

const state = () => ({
  currentEditProject: null,
  formData: {
    projectName: '',
    projectDesc: '',
    teams: [],
    databases: [],
    folders: []
  },
  teamRoleOptions: [
    // edit project才開放將權限設為resource_owner
    {
      text: i18n.t('resource_owner'),
      value: 'resource_owner'
    },
    {
      text: i18n.t('maintainer'),
      value: 'maintainer'
    },
    {
      text: i18n.t('viewer'),
      value: 'viewer'
    }
  ],
  defaultRole: 'maintainer'
})

const getters = {
  editModel: state => !!state.currentEditProject,
  getFormTeamByTeam: state => team => ({
    name: team?.name || team?.groupName,
    role: state.defaultRole,
    roleGrafana: state.defaultRole
  }),
  getSubTeamByFormTeam: state => formTeam => {
  // 設定project resource team permission

    return {
      name: formTeam.name,
      role: state.defaultRole
    }
  },
  getDefaultSubTeams: (state, getters) => {
    // 設定project resource team permission
    // 要用的人得自己從這邊深拷貝一份出來放在每個resource內
    return state.formData.teams.map(getters.getSubTeamByFormTeam)
    // .sort((a, b) => a.name.localeCompare(b.name))
  },
  resources: (state, __, rootState) => {
    // S3只要帶上 folder, 假如是編輯要額外帶上dashboard
    const measurementIds = state.formData.databases
      .map(db => db.resourceUuid)

    const folderIds = state.formData.folders
      .map(folder => folder.resourceUuid)

    const editProjectResources =
      (Array.isArray(state.currentEditProject?.resources) &&
        state.currentEditProject.resources) ||
      []

    return [
      rootState.influxBucketId,
      rootState.s3BucketId,
      rootState.grafanaId,
      ...measurementIds,
      ...folderIds,
      ...(editProjectResources
        .filter(resource => resource.resourceType === 'GRAFANA_DASHBOARD')
        .map(r => r.uuid) || [])
    ]
  },
  permissions: (state, _, rootState) => {
    // S3只要帶上 folder, 假如是編輯要額外帶上dashboard
    const editProjectPermissions =
      (Array.isArray(state.currentEditProject?.permissions) &&
        state.currentEditProject.permissions) ||
      []

    return [
      ...state.formData.teams.flatMap(team => [
        // team在project resource的permissions
        {
          type: 'group',
          name: team.name,
          role: 'viewer',
          resource_uuid: rootState.influxBucketId
        },
        {
          type: 'group',
          name: team.name,
          role: 'maintainer',
          resource_uuid: rootState.s3BucketId
        },
        {
          // Grafana permission, as dashboard default role
          type: 'group',
          name: team.name,
          role: team.roleGrafana,
          resource_uuid: rootState.grafanaId
        }
      ]),
      ...state.formData.databases
        .flatMap(db =>
          db.teams.map(team => ({
            type: 'group',
            name: team.name,
            role: team.role,
            resource_uuid: db.resourceUuid
          }))
        ),
      ...state.formData.folders.flatMap(folder =>
        folder.teams.map(team => ({
          type: 'group',
          name: team.name,
          role: team.role,
          resource_uuid: folder.resourceUuid
        }))
      ),
      // edit model 才要將原本的dashboard permissions加回去
      ...(editProjectPermissions
        .filter(permission => {
          // 找出 dashboard permissions
          return permission.resource.resourceType === 'GRAFANA_DASHBOARD'
        })
        .filter(permission => {
          // 只留下目前team的dashboard permissions
          const teamNames = state.formData.teams.map(team => team.name)

          return teamNames.includes(permission.group.groupName)
        })
        .map(permission => {
          return {
            type: 'group',
            name: permission.group.groupName,
            role: permission.role.roleName,
            resource_uuid: permission.resource.uuid
          }
        }) || [])
    ]
  },
  isFolderJoined: state => fileNode => state.formData.folders.some(folder => folder.resourceUuid === fileNode?.uuid)
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },

  setFormData: ({ commit }, payload) => {
    commit('setFormData', payload)
  },
  edit: ({ commit, state, rootState, getters }, { project } = {}) => {
    if (!project) return

    const projectPermissions =
      (Array.isArray(project.permissions) && project.permissions) || []

    // 編輯時以現有團隊為基準, 再撈回之前該團隊設定的腳色
    const projectTeamNames = project?.teamMembers?.map?.(t => t.groupName)
    const teams =
      rootState.teams.teams
        ?.map(t => getters.getFormTeamByTeam(t))
        .filter(team => projectTeamNames.includes(team.name))
        .map(team => {
          const roleGrafana =
            projectPermissions.find(
              permission =>
                permission.resource.resourceType === 'GRAFANA_ROOT' &&
                permission.group.groupName === team.name
            )?.role?.roleName || state.defaultRole

          return {
            ...team,
            roleGrafana
          }
        }) || []

    const formatFormResourceWithTeams = (resourceType) => {
      return project.resources
        .filter(resource => resource.resourceType === resourceType)
        .map(({ uuid }) => {
          const permissions = projectPermissions.filter(
            p => p.resource.uuid === uuid
          )

          return {
            resourceUuid: uuid,
            teams: teams.map(formTeam => {
              const role = permissions.find(
                p => p.group.groupName === formTeam.name
              )?.role.roleName
              const defaultSubTeam = getters.getSubTeamByFormTeam(formTeam)

              return {
                ...defaultSubTeam,
                role: role || defaultSubTeam.role
              }
            })
          }
        })
    }

    const databases = formatFormResourceWithTeams('INFLUX_MEASUREMENT')
    const folders = formatFormResourceWithTeams('S3_FOLDER')

    commit('setFormData', {
      projectName: project.projectName || '',
      projectDesc: project.projectDesc || '',
      teams,
      databases,
      folders
    })

    commit('setState', {
      currentEditProject: project
    })
  },
  submit: async ({ state, rootState, dispatch, getters }) => {
    const { influxBucketId, s3BucketId, grafanaId } = rootState

    if (!influxBucketId || !s3BucketId || !grafanaId) {
      const error = await dispatch('fetchResourceIds', null, {
        root: true
      }).catch(error => error)

      const { influxBucketId, s3BucketId, grafanaId } = rootState

      if (!influxBucketId || !s3BucketId || !grafanaId) {
        return Promise.reject(error)
      }
    }

    const payload = {
      project_name: state.formData.projectName,
      project_desc: state.formData.projectDesc,
      user_members: [],
      team_members: state.formData.teams.map(team => team.name),
      resources: getters.resources,
      permissions: getters.permissions
    }

    // edit model
    if (getters.editModel) {
      const projectUuid = state?.currentEditProject?.uuid

      return apiProjectProjects.put(projectUuid, payload)
    }
    // create model
    return apiProjectProjects.post(payload)
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setFormData: (state, payload) => {
    Object.assign(state.formData, payload)
  }
}

export const form = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
