import i18n from '@/i18n'

const state = () => ({
  snackColor: '#00abeb',
  snack: false,
  snackText: '',
  snackTimeout: 5000,
  x: null,
  y: 'top'
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  showMessage: ({ commit }, payload) => {
    commit('setState', {
      ...payload,
      snack: true
    })
  },

  showError: (
    { commit },
    { content = '', color = 'error', timeout = 5000, ...payload } = {}
  ) => {
    const snackText = String(
      content || new Error(i18n.t('api_errors.unknown_error'))
    )

    commit('setState', {
      snackColor: color,
      snack: true,
      snackText,
      snackTimeout: timeout,
      ...payload
    })
  },

  showWarning: (
    { commit },
    { content = '', color = 'warning', timeout = 5000, ...payload } = {}
  ) => {
    const snackText = String(
      content
    )

    commit('setState', {
      snackColor: color,
      snack: true,
      snackText,
      snackTimeout: timeout,
      ...payload
    })
  },

  showSuccess: (
    { commit },
    { content = '', color = 'success', timeout = 5000, ...payload } = {}
  ) => {
    commit('setState', {
      snackColor: color,
      snack: true,
      snackText: content,
      snackTimeout: timeout,
      ...payload
    })
  },

  close: ({ commit }) => {
    commit('setState', {
      snack: false
    })
  }
}

const mutations = {
  setState: (state, payload) => {
    Object.assign(state, payload)
  }
}

export const snackbar = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
