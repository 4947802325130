import { LayerNode } from './LayerNode'

import { setProperties, SETTINGS_KEYS } from '@/models/utils'

import { cloneDeep } from 'lodash'

import {
  MercatorCoordinate as MbxMercatorCoordinate,
  LngLatBounds as MbxLngLatBounds
} from 'mapbox-gl'

import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders'

BABYLON.SceneLoader.ShowLoadingScreen = false

const regHideMeshName = /__boundingbox.*__/g
const regIgnoreMeshName = /__.*__/g
const regSliceMeshName = /.*-slice-[xyz].*/g

export class Layer3DNode extends LayerNode {
  type
  blob // blob of 3D file content
  blobUrl
  center
  world
  map
  engine
  scene
  light
  mercatorCenter
  scaleFactor
  oriBoundingBox
  meshParent
  sourceMesh
  glbMeshes = []
  sliceHelpMeshes
  isSlicing = false
  isDraging = false
  isAnimation = false
  mapboxCameraMatrix = []
  matrix = []

  constructor(uuid, config = {}) {
    const { type, blob, center, ...layerNodeConfig } = config

    super(uuid, layerNodeConfig)

    // delete this.meshParent
    // this.meshParent = null

    blob && this.setBlob(blob)
    center && this.setCenter(center)

    setProperties(this, {
      type: type ?? this.type
    })
  }

  // getter/setter
  get mapsetNode() {
    return this.parent
  }

  get folderNode() {
    return this.parent?.parent
  }

  get meshes() {
    if (!this.scene) {
      return []
    }

    const settings = this.stagingFile
      ? this.stagingFile.settings
      : this.settings

    return this.scene.meshes
      // .filter(mesh => mesh.name.includes('__boundingbox') || !mesh.name?.match(regIgnoreMeshName))
      .filter(mesh => !mesh.name?.match(regIgnoreMeshName))
      .map(mesh => {
        const meshSettings = settings?.meshes?.find?.(sMesh => sMesh.name === mesh.name)

        setProperties(mesh, {
          settings: meshSettings || {}
        })

        return mesh
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  get skyAxis() {
    return this.settings[SETTINGS_KEYS.SKY_AXIS.key]
  }

  get meshBoundingBox() {
    return this.scene.meshes.find(mesh => mesh.id === '__boundingbox__')
  }

  get mapBoundingBox() {
    /**
     *  從mercatorCenter經過meshParent scale過的size計算mapbox boundingBox
     *  @returns LngLatBounds
    **/

    return this.getMapBoundingBoxByMesh(this.meshParent)
  }

  get stagingMeshes() {
    const oriMeshes = this.glbMeshes.map(mesh => mesh.id)

    return this.meshes.filter(mesh => !oriMeshes.includes(mesh.id))
  }

  get boundingBoxSize() {
    const boundingBox = this.oriBoundingBox

    const size = {
      x: boundingBox.maximumWorld.x - boundingBox.minimumWorld.x,
      y: boundingBox.maximumWorld.y - boundingBox.minimumWorld.y,
      z: boundingBox.maximumWorld.z - boundingBox.minimumWorld.z
    }

    return size
  }

  get meshSliceGroundYZ() {
    return this?.sliceHelpMeshes?.getChildren?.()?.find(mesh => mesh.id === '__sliceGroundYZ__')
  }

  get meshSliceGroundXZ() {
    return this?.sliceHelpMeshes?.getChildren?.()?.find(mesh => mesh.id === '__sliceGroundXZ__')
  }

  get meshSliceGroundXY() {
    return this?.sliceHelpMeshes?.getChildren?.()?.find(mesh => mesh.id === '__sliceGroundXY__')
  }

  // methods
  setBlob(blob) {
    /**
     * @param {Blob} blob
     * @returns self
    **/

    if (!(blob instanceof Blob)) {
      console.debug('Cannot set blob which is not Blob instance')

      return this
    }

    this.setProperties({
      blob
    })
    this.setBlobUrl(blob)

    return this
  }

  setBlobUrl(blob) {
    /**
     * @param {Blob} blob
     * @returns self
    **/

    if (!(blob instanceof Blob)) {
      console.debug('Cannot set blob which is not Blob instance')

      return this
    }

    this.setProperties({
      blobUrl: URL.createObjectURL(blob)
    })

    return this
  }

  setCenter(center) {
    /**
     * @param {Array} center
     * @returns self
    **/

    if (!Array.isArray(center)) {
      console.debug('Cannot set center which is not Array instance')

      return this
    }

    this.center = center

    return this
  }

  render(repaintMap = true, renderScene = true) {
    if (renderScene) {
      // wipeCaches要在scene render之前
      this.engine.wipeCaches(true)
      // activeCamera要freeze
      this.scene.activeCamera.freezeProjectionMatrix(BABYLON.Matrix.FromArray(this.matrix))
      this.scene.render(false)
    }

    if (repaintMap) {
      this.map.triggerRepaint()
    }
  }

  toggleMeshVisible(mesh, visible) {
    /**
     *  @param {Mesh} mesh - Babylonjs Mesh
     *  @returns self
    **/
    mesh.setEnabled(visible ?? !mesh.isEnabled())

    this.render(true, true)

    return this
  }

  addWireframeToMesh(mesh, visiable = false) {
    const wireframeName = `__wireframe${mesh.name}__`
    const wireframe = mesh.clone(wireframeName, mesh)
    wireframe.setParent(mesh)
    wireframe.material = new BABYLON.StandardMaterial(`wireframeMat-${mesh.name}`, this.scene)
    // wireframe.material.emissiveColor = BABYLON.Color3.Black()
    wireframe.edgesColor = new BABYLON.Color3.Black()
    wireframe.material.wireframe = true

    this.toggleMeshVisible(wireframe, visiable)
  }

  getWireframeMesh(mesh) {
    const wireframeName = `__wireframe${mesh.name}__`
    const [wireframe] = mesh.getChildMeshes(true, node => node.name === wireframeName)
    return wireframe
  }

  toggleWireframeVisible(mesh, visiable) {
    if (!mesh) {
      return
    }

    const wireframe = this.getWireframeMesh(mesh)

    if (!wireframe) {
      return
    }

    this.toggleMeshVisible(wireframe, visiable)
  }

  getMeshesByScalarsName(scalarsName) {
    return this.meshes.filter(mesh => {
      return mesh?.settings?.activeScalarsName === scalarsName
    })
  }

  importMesh(blob, updatedMeshes = []) {
    const blobUrl = URL.createObjectURL(blob)

    // parent把meshes group起來, 藉此把meshes移到中心點
    // this.meshParent = new BABYLON.Mesh('__meshgroup__', this.scene)
    updatedMeshes.forEach(mesh => {
      this.scene.removeMesh(mesh)
    })
    return new Promise((resolve, reject) => {
      BABYLON.SceneLoader.ImportMesh(null, blobUrl, '', this.scene,
        (meshes, particleSystems, skeleton, animationGroups) => {
          updatedMeshes.forEach(updatedMeshe => {
            meshes.some(mesh => mesh.id === updatedMeshe.id)
              ? updatedMeshe.dispose()
              : this.scene.addMesh(updatedMeshe)
          })

          const currentWireframeVisible = Boolean(this.getWireframeMesh(this.sourceMesh)?.isEnabled?.())
          meshes.forEach(mesh => {
            if (mesh.id === '__root__') {
              return
            }
            if (mesh.id.match(regHideMeshName)) {
              this.toggleMeshVisible(mesh, false)
            }

            if (mesh.id === this.sourceMesh?.id) {
              this.sourceMesh = mesh
            }
            this.addWireframeToMesh(mesh, currentWireframeVisible)
            // 假設新增的mesh都從原本的mesh而來, 一定是原本mesh對應的相對位置
            // const centerWorld = mesh.getBoundingInfo().boundingBox.centerWorld.clone()
            this.centerMesh(mesh, this.oriBoundingBox)
          })

          // FIXME: importMesh第二次, mesh的boundingBox就會怪怪的
          // // Set meshParent bounding
          // let min = this.meshParent.getBoundingInfo().boundingBox.minimumWorld
          // let max = this.meshParent.getBoundingInfo().boundingBox.maximumWorld
          // console.log(`min:${min}, max:${max}`)

          // meshes.forEach(mesh => {
          //   // NOTE: __root__ size為0, 不該加到meshParent
          //   if (mesh.id === '__root__') {
          //     return
          //   }
          //   mesh.setParent(this.meshParent)

          //   const meshMin = mesh.getBoundingInfo().boundingBox.minimumWorld
          //   const meshMax = mesh.getBoundingInfo().boundingBox.maximumWorld

          //   min = BABYLON.Vector3.Minimize(min ?? meshMin, meshMin)
          //   max = BABYLON.Vector3.Maximize(max ?? meshMax, meshMax)
          //   console.log(`${mesh.id}, meshMin:${meshMin}, meshMax:${meshMax}, min:${min}, max:${max}`)
          // })
          // this.meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max))

          // TRICKY: 讓會動的檔案可以repaint map
          if (animationGroups?.length) {
            this.isAnimation = true
          }

          this.render()

          resolve(meshes.filter(mesh => mesh.id !== '__root__'))
        }, null, (scene, message, error) => {
          updatedMeshes.forEach(mesh => {
            this.scene.addMesh(mesh)
          })

          this.render()

          reject(error)
        }, `.${this.fileType}`)
    })
  }

  createSliceHelpMeshes(point = { x: 0, y: 0, z: 0 }) {
    this.sliceHelpMeshes = new BABYLON.Mesh('__slicegrounds__', this.scene)
    const material = new BABYLON.StandardMaterial('groundMaterial', this.scene)
    material.alpha = 0.7
    material.emissiveColor = new BABYLON.Color3.Gray()
    material.disableLighting = true
    // material.useAlphaFromAlbedoTexture = true

    // material.backFaceCulling = false
    // material.transparencyMode = BABYLON.Material.MATERIAL_ALPHATESTANDBLEND
    // material.needDepthPrePass = true
    // material.albedoTexture.hasAlpha = true

    const size = getMeshSize(this.meshParent)
    const sizeX = size.x * 1.2
    const sizeY = size.y * 1.2
    const sizeZ = size.z * 1.2
    const boundingBox = this.meshParent.getBoundingInfo().boundingBox

    // groundXZ
    const groundXZ = BABYLON.MeshBuilder.CreateGround('__sliceGroundXZ__', {
      width: sizeX,
      height: Math.max(sizeZ, sizeX * 0.6)
    }, this.scene)
    // groundXZ.isPickable = false
    if (this.skyAxis !== 'y') {
      groundXZ.position.y = point.y
    }
    // groundXZ.position = boundingBox.center.clone()
    groundXZ.position[this.skyAxis] = boundingBox.center[this.skyAxis]
    groundXZ.material = material
    groundXZ.setParent(this.sliceHelpMeshes)

    // groundYZ
    const groundYZ = BABYLON.MeshBuilder.CreateGround('__sliceGroundYZ__', {
      width: sizeY,
      height: Math.max(sizeZ, sizeY * 0.6)
    }, this.scene)
    // groundYZ.isPickable = false
    if (this.skyAxis !== 'x') {
      groundYZ.position.x = point.x
    }
    // groundYZ.position = boundingBox.center.clone()
    groundYZ.position[this.skyAxis] = boundingBox.center[this.skyAxis]
    groundYZ.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2)
    groundYZ.material = material
    groundYZ.setParent(this.sliceHelpMeshes)

    // groundXY
    const groundXY = BABYLON.MeshBuilder.CreateGround('__sliceGroundXY__', {
      width: sizeX,
      height: sizeY
    }, this.scene)
    // groundXY.isPickable = false
    if (this.skyAxis !== 'z') {
      groundXY.position.z = point.z
    }
    // groundXY.position = boundingBox.center.clone()
    groundXY.position[this.skyAxis] = boundingBox.center[this.skyAxis]
    groundXY.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0)
    groundXY.material = material
    groundXY.setParent(this.sliceHelpMeshes)
  }

  onPointerObservable({ event, pickInfo, type }) {
    if (type === BABYLON.PointerEventTypes.POINTERMOVE) {
      this.render()
    }
  }

  startSlice() {
    this.meshParent.showBoundingBox = true
    if (!this.sourceMesh.isEnabled()) {
      this.toggleMeshVisible(this.sourceMesh)
    }
    this.createSliceHelpMeshes()

    this.render()
  }

  endSlice() {
    this.meshParent.showBoundingBox = false

    if (!this.sliceHelpMeshes) {
      this.render()
      return
    }

    const grounds = this.sliceHelpMeshes.getChildren()

    const gXZ = grounds.find(ground => ground.id === '__sliceGroundXZ__')
    const gYZ = grounds.find(ground => ground.id === '__sliceGroundYZ__')
    const gXY = grounds.find(ground => ground.id === '__sliceGroundXY__')

    const interceptPoint = new BABYLON.Vector3(gYZ.position.x, gXZ.position.y, gXY.position.z)

    this.sliceHelpMeshes.dispose()
    this.sliceHelpMeshes = null
    this.render()

    let interceptPointArr = []
    switch (this.skyAxis) {
      case 'y':
        interceptPointArr = [
          interceptPoint.x + this.oriBoundingBox.centerWorld.x,
          // interceptPoint.y + this.oriBoundingBox.minimumWorld.y,
          interceptPoint.y,
          interceptPoint.z + this.oriBoundingBox.centerWorld.z
        ]
        break
      case 'z':
        interceptPointArr = [
          interceptPoint.x + this.oriBoundingBox.centerWorld.x,
          interceptPoint.y + this.oriBoundingBox.centerWorld.y,
          // interceptPoint.z + this.oriBoundingBox.minimumWorld.z
          interceptPoint.z
        ]
        break
    }

    return interceptPointArr
  }

  getMapBoundingBoxByMesh(mesh) {
    /**
     *  從mercatorCenter經過meshParent scale過的size計算mapbox boundingBox
     *  @param {Mesh} mesh - Babylonjs Mesh
     *  @returns LngLatBounds
    **/

    const mc = this.mercatorCenter // center_xyz (unit: m in mercator)
    const size = getMeshSize(mesh) // size by mesh boundingbox (unit: m)
    const scaleFactor = this.scaleFactor // 單位轉換: m -> m in mercator

    const boundingBox = new MbxLngLatBounds() // mapbox boundingbox (unit: m in mercator)

    // get sizeY by skyAxis
    const skyAxis = this.skyAxis
    let rightAxis = 'y'
    switch (skyAxis) {
      case 'y':
        rightAxis = 'z'
    }

    const { x: sizeX, [rightAxis]: sizeY } = size

    const sw = new MbxMercatorCoordinate(
      (mc.x - (sizeX * scaleFactor) / 2),
      (mc.y - (sizeY * scaleFactor) / 2),
      0
    ).toLngLat()

    const ne = new MbxMercatorCoordinate(
      (mc.x + (sizeX * scaleFactor) / 2),
      (mc.y + (sizeY * scaleFactor) / 2),
      0
    ).toLngLat()

    boundingBox.extend(sw)
    boundingBox.extend(ne)

    return boundingBox
  }

  computeWorld() {
    // const rotateX = Math.PI / 2; const rotateY = 0; const rotateZ = -Math.PI / 5.8
    let rotateX = 0; let rotateY = 0; const rotateZ = 0
    const skyAxis = this.skyAxis
    if (skyAxis) {
      switch (skyAxis) {
        case 'x':
          rotateY = -Math.PI / 2
          break
        case 'y':
          rotateX = Math.PI / 2
          break
      }
    }

    // 從經緯度得到的墨卡托座標中心點(x, y, z)
    this.mercatorCenter = MbxMercatorCoordinate.fromLngLat(
      this.center,
      1
    )

    this.world = BABYLON.Matrix.Identity().setTranslationFromFloats(this.mercatorCenter.x, this.mercatorCenter.y, this.mercatorCenter.z)

    // Distance of 1 meter in mercatorCenter units. 只和給入的LngLat有關
    // ref: https://docs.mapbox.com/mapbox-gl-js/api/geography/#mercatorcoordinate#meterinmercatorcoordinateunits
    this.scaleFactor = this.mercatorCenter.meterInMercatorCoordinateUnits()

    // Y軸負號是將Babylon世界改為右手坐標系
    const scaleMatrix = BABYLON.Matrix.Scaling(this.scaleFactor, -this.scaleFactor, this.scaleFactor)

    this.world = scaleMatrix.multiply(this.world)
    this.world = BABYLON.Matrix.RotationX(rotateX).multiply(this.world)
    this.world = BABYLON.Matrix.RotationY(rotateY).multiply(this.world)
    this.world = BABYLON.Matrix.RotationZ(rotateZ).multiply(this.world)
  }

  centerMesh(mesh, boundingBox) {
    const skyAxis = this.skyAxis

    // // Move meshParent to center by pivot
    mesh.setPivotPoint(boundingBox.centerWorld, BABYLON.Space.WORLD)
    // const upAxisTranslate = boundingBox.minimumWorld[skyAxis]
    let pivotAt = mesh.getPivotPoint()

    // // 根據bounding size, 在Babylon內平移物件
    // // const parentSize = getMeshSize(this.meshParent)

    switch (skyAxis) {
      case 'y':
        pivotAt = pivotAt.multiply(new BABYLON.Vector3(-1, 0, -1))
        // pivotAt = pivotAt.add(new BABYLON.Vector3(0, -upAxisTranslate, 0))

        // 根據bounding size, 在Babylon內平移物件
        // pivotAt = pivotAt.add(new BABYLON.Vector3(parentSize.x / 2, 0, parentSize.z / 2))
        break
      case 'z':
        pivotAt = pivotAt.multiply(new BABYLON.Vector3(-1, -1, 0))
        // pivotAt = pivotAt.add(new BABYLON.Vector3(0, 0, -upAxisTranslate))

        // 根據bounding size, 在Babylon內平移物件
        // pivotAt = pivotAt.add(new BABYLON.Vector3(parentSize.x / 2, parentSize.y / 2, 0))

        break
    }
    mesh.setPivotPoint(pivotAt)
    mesh.position = pivotAt
  }

  onWindowResize() {
    this.engine.resize()
  }

  toMapboxLayer(callback) {
    /**
     * Bablonjs in Mapbox solution
     * ref: https://forum.babylonjs.com/t/mapbox-gl-to-babylon-camera-projection/9972
     *
     * @param {Array} center
     * @returns self
    **/

    return {
      id: this.uuid,
      type: this.type,
      // type: 'custom',
      layout: {
        visibility: this.visibility // no work to 3d layer, WTF?
      },
      renderingMode: '3d',
      onAdd: (map, gl) => {
        this.map = map

        this.engine = new BABYLON.Engine(gl, true)
        // window.addEventListener('resize', this.onWindowResize.bind(this))

        this.scene = new BABYLON.Scene(this.engine)
        this.scene.activeCamera = new BABYLON.Camera('mapboxCamera', new BABYLON.Vector3(0, 0, 0), this.scene)
        this.scene.autoClear = false
        this.scene.detachControl()
        // 巴比倫世界投影到Mapbox地圖上的時候從左手坐標系改為右手坐標系
        // 所以巴比倫世界內的場景也要改為右手坐標系
        this.scene.useRightHandedSystem = true
        this.scene.onPointerObservable.add(this.onPointerObservable.bind(this))
        this.scene.onNewMaterialAddedObservable.add(mat => {
          const ignoreMatNames = ['TextPlaneMaterial']
          if (ignoreMatNames.includes(mat.name)) {
            return
          }

          mat.backFaceCulling = false
        })

        let lightDirection
        switch (this.skyAxis) {
          case 'y': lightDirection = new BABYLON.Vector3(0, 1, 0)
            break
          case 'z': lightDirection = new BABYLON.Vector3(0, 0, 1)
            break
          default: lightDirection = new BABYLON.Vector3(0, 0, 1)
        }
        // this.light = new BABYLON.DirectionalLight('hemi', new BABYLON.Vector3(0, 0, -1), this.scene)
        this.light = new BABYLON.HemisphericLight('hemi', lightDirection, this.scene)
        this.light.intensity = 0.7

        window.dispatchEvent(new Event('resize'))
        this.computeWorld()

        // parent把meshes group起來, 藉此把meshes移到中心點
        this.meshParent = new BABYLON.Mesh('__meshgroup__', this.scene)
        BABYLON.SceneLoader.ImportMesh(null, this.blobUrl, '', this.scene,
          (meshes, particleSystems, skeleton, animationGroups) => {
            // Set meshParent bounding
            let min, max
            // const hl = new BABYLON.HighlightLayer('hl1', this.scene)
            meshes.forEach(mesh => {
              // NOTE: __root__ size為0, 不該加到meshParent
              if (mesh.id === '__root__') {
                return
              }
              if (mesh.id.match(regHideMeshName)) {
                this.toggleMeshVisible(mesh, false)
              }
              mesh.setParent(this.meshParent)

              this.addWireframeToMesh(mesh, false)

              const meshMin = mesh.getBoundingInfo().boundingBox.minimumWorld
              const meshMax = mesh.getBoundingInfo().boundingBox.maximumWorld

              min = BABYLON.Vector3.Minimize(min ?? meshMin, meshMin)
              max = BABYLON.Vector3.Maximize(max ?? meshMax, meshMax)
            })

            this.meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max))

            this.sourceMesh = this.meshes
              .filter(mesh => !mesh.id.match(regSliceMeshName))
              .shift()
            this.glbMeshes = this.meshes.slice()

            // 紀錄原始的boundingBox, 之後importMesh時使用
            this.oriBoundingBox = this.meshBoundingBox
              ? cloneDeep(this.meshBoundingBox.getBoundingInfo().boundingBox)
              : cloneDeep(this.meshParent.getBoundingInfo().boundingBox)

            this.centerMesh(this.meshParent, this.oriBoundingBox)

            // Debug tool
            // console.log(getMeshSize(this.meshParent))
            // const maxSize = Math.max(
            //   ...Object.values(getMeshSize(this.meshParent))
            //     .filter(el => typeof el === 'number')
            // )
            // showAxis(this.scene, maxSize * 1.2)
            // this.meshParent.showBoundingBox = true

            // // TRICKY: 讓會動的檔案可以repaint map
            if (animationGroups?.length) {
              this.isAnimation = true
            }

            if (typeof callback === 'function') {
              // this.scene.onReadyObservable不知為何no work
              const watingCallBack = () => {
                if (!this.scene.isReady()) {
                  return setTimeout(watingCallBack, 200)
                }
                callback()
              }
              watingCallBack()
            }
          }, null,
          (scene, message, error) => {
            return error
          }, `.${this.fileType}`)
      },
      onRemove: () => {
        // window.removeEventListener('resize', this.onWindowResize)

        if (
          !this.stagingFile &&
          this.blobUrl
        ) {
          URL.revokeObjectURL(this.blobUrl)
        }
      },
      render: (gl, mbxCameraMatrix) => {
        // mapbox會一直觸發 render, 無論camera是否改變
        // mbxCameraMatrix 是 mapbox camera 的轉置矩陣

        // 還沒load完不要進去
        if (!this.scene.isReady()) {
          return
        }

        // mbxCameraMatrix 有改變再觸發repaint節省效能
        const isSameMatrix = this.mapboxCameraMatrix.every((item, iItem) => item === mbxCameraMatrix[iItem])
        if (
          this.mapboxCameraMatrix.length &&
          !this.isAnimation && // TRICKY: 讓會動的檔案可以repaint map
          isSameMatrix
        ) {
          this.render(false)

          return
        }

        this.mapboxCameraMatrix = mbxCameraMatrix

        this.matrix = mmultiply(this.world._m, this.mapboxCameraMatrix, this.scene.activeCamera._projectionMatrix._m)

        this.render()
      }
    }
  }
}

const getMeshSize = mesh => {
  const boundingBox = mesh.getBoundingInfo().boundingBox
  const size = {
    x: boundingBox.maximum.x - boundingBox.minimum.x,
    y: boundingBox.maximum.y - boundingBox.minimum.y,
    z: boundingBox.maximum.z - boundingBox.minimum.z
  }

  return size
}

// modified from bjs matrix class
const mmultiply = (mat1, mat2, result) => {
  const m = mat1
  const otherM = mat2
  const offset = 0
  var tm0 = m[0]; var tm1 = m[1]; var tm2 = m[2]; var tm3 = m[3]
  var tm4 = m[4]; var tm5 = m[5]; var tm6 = m[6]; var tm7 = m[7]
  var tm8 = m[8]; var tm9 = m[9]; var tm10 = m[10]; var tm11 = m[11]
  var tm12 = m[12]; var tm13 = m[13]; var tm14 = m[14]; var tm15 = m[15]

  var om0 = otherM[0]; var om1 = otherM[1]; var om2 = otherM[2]; var om3 = otherM[3]
  var om4 = otherM[4]; var om5 = otherM[5]; var om6 = otherM[6]; var om7 = otherM[7]
  var om8 = otherM[8]; var om9 = otherM[9]; var om10 = otherM[10]; var om11 = otherM[11]
  var om12 = otherM[12]; var om13 = otherM[13]; var om14 = otherM[14]; var om15 = otherM[15]

  result[offset] = tm0 * om0 + tm1 * om4 + tm2 * om8 + tm3 * om12
  result[offset + 1] = tm0 * om1 + tm1 * om5 + tm2 * om9 + tm3 * om13
  result[offset + 2] = tm0 * om2 + tm1 * om6 + tm2 * om10 + tm3 * om14
  result[offset + 3] = tm0 * om3 + tm1 * om7 + tm2 * om11 + tm3 * om15

  result[offset + 4] = tm4 * om0 + tm5 * om4 + tm6 * om8 + tm7 * om12
  result[offset + 5] = tm4 * om1 + tm5 * om5 + tm6 * om9 + tm7 * om13
  result[offset + 6] = tm4 * om2 + tm5 * om6 + tm6 * om10 + tm7 * om14
  result[offset + 7] = tm4 * om3 + tm5 * om7 + tm6 * om11 + tm7 * om15

  result[offset + 8] = tm8 * om0 + tm9 * om4 + tm10 * om8 + tm11 * om12
  result[offset + 9] = tm8 * om1 + tm9 * om5 + tm10 * om9 + tm11 * om13
  result[offset + 10] = tm8 * om2 + tm9 * om6 + tm10 * om10 + tm11 * om14
  result[offset + 11] = tm8 * om3 + tm9 * om7 + tm10 * om11 + tm11 * om15

  result[offset + 12] = tm12 * om0 + tm13 * om4 + tm14 * om8 + tm15 * om12
  result[offset + 13] = tm12 * om1 + tm13 * om5 + tm14 * om9 + tm15 * om13
  result[offset + 14] = tm12 * om2 + tm13 * om6 + tm14 * om10 + tm15 * om14
  result[offset + 15] = tm12 * om3 + tm13 * om7 + tm14 * om11 + tm15 * om15
  return result
}

// const showAxis = (scene, size) => {
//   const makeTextPlane = function(text, color, size) {
//     var dynamicTexture = new BABYLON.DynamicTexture('DynamicTexture', 50, scene, true)
//     dynamicTexture.hasAlpha = true
//     dynamicTexture.drawText(text, 5, 40, 'bold 36px Arial', color, 'transparent', true)
//     var plane = new BABYLON.Mesh.CreatePlane(`__text_${text}__`, size, scene, true)
//     plane.material = new BABYLON.StandardMaterial('TextPlaneMaterial', scene)
//     plane.material.backFaceCulling = false
//     plane.material.specularColor = new BABYLON.Color3(0, 0, 0)
//     plane.material.diffuseTexture = dynamicTexture
//     return plane
//   }

//   const axisX = BABYLON.Mesh.CreateLines('__axis_X__', [
//     new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
//     new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
//   ], scene)
//   axisX.color = new BABYLON.Color3(1, 0, 0)
//   const xChar = makeTextPlane('X', 'red', size / 10)
//   xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0)
//   var axisY = BABYLON.Mesh.CreateLines('__axis_Y__', [
//     new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(-0.05 * size, size * 0.95, 0),
//     new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(0.05 * size, size * 0.95, 0)
//   ], scene)
//   axisY.color = new BABYLON.Color3(0, 1, 0)
//   const yChar = makeTextPlane('Y', 'green', size / 10)
//   yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size)
//   var axisZ = BABYLON.Mesh.CreateLines('__axis_Z__', [
//     new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
//     new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, 0.05 * size, size * 0.95)
//   ], scene)
//   axisZ.color = new BABYLON.Color3(0, 0, 1)
//   const zChar = makeTextPlane('Z', 'blue', size / 10)
//   zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size)
// }

// const showMeshNamePlane = function(scene, mesh) {
//   console.log(mesh.name)

//   // Set font type
//   const fontType = 'Arial'

//   const groundWidth = 10000
//   const groundHeight = groundWidth / 2
//   const ground = BABYLON.MeshBuilder.CreateGround('ground1', { width: groundWidth, height: groundHeight }, scene)
//   ground.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0)
//   // Set width and height for dynamic texture using same multiplier
//   // const DTWidth = groundWidth * 25.6
//   // const DTHeight = groundWidth * 25.6
//   const DTWidth = 5120
//   const DTHeight = DTWidth / 2
//   const dynamicTexture = new BABYLON.DynamicTexture('DynamicTexture', { width: DTWidth, height: DTHeight }, scene)
//   const mat = new BABYLON.StandardMaterial('TextPlaneMaterial', scene)
//   mat.diffuseTexture = dynamicTexture
//   // mat.diffuseTexture.hasAlpha = true
//   ground.material = mat
//   // ground.useAlphaFromDiffuseTexture = true
//   // ground.position = mesh.getBoundingInfo().boundingBox.center
//   // Set text
//   const text = mesh.name
//   const size = 12 // any value will work
//   const ctx = dynamicTexture.getContext()
//   ctx.font = size + 'px ' + fontType
//   const textWidth = ctx.measureText(text).width
//   const ratio = textWidth / size
//   const fontSize = Math.floor(DTWidth / (ratio * 1)) // size of multiplier (1) can be adjusted, increase for smaller text
//   const font = fontSize + 'px ' + fontType
//   dynamicTexture.drawText(text, null, null, font, 'green', 'white', true, true)

//   // Create dynamic texture

//   // mat.diffuseTexture.hasAlpha = true

//   // Check width of text for given font type at any size of font

//   // Calculate ratio of text width to size of font used

//   // set font to be actually used to write text on dynamic texture

//   // apply material
//   // plane.position = mesh.getBoundingInfo().boundingBox.center
//   // plane.useAlphaFromDiffuseTexture = true
//   // Draw text
// }
