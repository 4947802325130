import { setProperties } from '@/models/utils'

import { chunk, truncate } from 'lodash'

import { LngLatBounds } from 'mapbox-gl'

export class Source {
  uuid
  data

  constructor(uuid, { data } = {}) {
    this.uuid = uuid

    setProperties(this, {
      uuid,
      data: data ?? this.data
    })

    return this
  }

  // getter/setter
  get type() {
    return this.data?.type
  }

  get geoJsonData() {
    if (!this.data) {
      return null
    }

    const geoJsonData = normalizeGeojson(this.data?.data)

    return geoJsonData
  }

  get boundingBox() {
    if (!this.geoJsonData) {
      return null
    }

    const bounds = new LngLatBounds()
    this.geoJsonData.features.forEach(feature => {
      const coords = chunk(
        flattenDeepCoordinates(feature.geometry.coordinates),
        2
      )

      coords.forEach(coord => {
        try {
          bounds.extend(coord)
        } catch (error) {
          console.log(error)
        }
      })
    })

    return bounds
  }

  // methods
  searchFeatureProperty(featureProperties, queryText) {
    if (!featureProperties) {
      return false
    }

    if (!queryText) {
      return true
    }

    const propertyEntries = Object.entries(featureProperties)

    const matchIndex = propertyEntries.findIndex(([key, value]) => {
      const searchText = queryText.toLowerCase()
      const keyName = `${key}`.toLowerCase()
      const text = `${value}`.toLowerCase()

      return `${keyName}:${text}`.includes(searchText)
    })

    if (matchIndex === -1) {
      return false
    }

    const prevMatch = propertyEntries[matchIndex - 1]?.join(':') || ''
    const match = `${propertyEntries[matchIndex][0]}:${propertyEntries[matchIndex][1]}`
    const nextMatch = propertyEntries[matchIndex + 1]?.join(':') || ''

    // return `${truncateString(prevMatch)}${match}${nextMatch ? `, ${truncateString(nextMatch)}` : ''}`
    return {
      prevMatch: prevMatch ? `${truncateString(prevMatch)}, ` : '',
      match,
      nextMatch: nextMatch ? `, ${truncateString(nextMatch)}` : ''
    }
  }
}

const truncateString = str => {
  return truncate(str, { length: 20 })
}

const normalizeGeojson = (geojson) => {
  if (geojson.type === 'FeatureCollection') {
    return Object.assign(
      {},
      geojson,
      {
        features: geojson.features.reduce(
          (result, each) => result.concat(normalizeGeojson(each)),
          []
        )
      }
    )
  }
  if (geojson.type === 'Feature' && geojson.geometry.type === 'GeometryCollection') {
    return geojson.geometry.geometries.map((geometry) => Object.assign(
      {},
      geojson,
      { geometry }
    ))
  }

  return geojson
}

const flattenDeepCoordinates = coords => {
  if (!Array.isArray(coords)) {
    return coords
  }

  if (!Array.isArray(coords[0])) {
    return coords.slice(0, 2)
  }

  const result = coords.reduce((acc, cur) => {
    return [
      ...acc,
      ...flattenDeepCoordinates(cur)
    ]
  }, [])

  return result
}
