import { createTarget } from './target'

export const newPanelIdByDashboard = dashboard => {
  // NOTE: Grafana 7.5.3目前測試Panel id為0時, snapshot會撈不到資料
  if (!dashboard) return
  // const oriPanels = dashboard?.panels || []
  const panelIds = dashboard?.panels
    ?.map?.(panel => Number(panel.id))
    ?.sort?.((a, b) => a - b)

  if (!Array.isArray(panelIds) || panelIds.length === 0) {
    return 1
  }

  if (panelIds[0] > 1) return 1

  if (panelIds.length === 1) {
    const [id] = panelIds

    return id + 1
  }

  const newId =
    panelIds.find((id, iId, arr) => {
      const nextId = arr[iId + 1]
      if (!nextId) return true
      return nextId - id > 1
    }) + 1

  return newId

  // return (
  //   (Array.isArray(oriPanels) &&
  //     oriPanels
  //       .map(p => Number(p.id))
  //       .sort()
  //       .pop() + 1) ||
  //   0
  // )
}

export const createPanel = (title = '', type = 'graph') => {
  return {
    title,
    type,
    datasource: '',
    targets: [createTarget('Untitle Data 1')],
    lines: true,
    linewidth: 1,
    gridPos: {
      h: 8,
      w: 12,
      x: 0,
      y: 0
    }
  }
}
