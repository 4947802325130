import { form } from './panel-form'

import { formatQuery, formatSnapshotData } from '@/assets/js/grafana/model'

import {
  apiGrafanaGrafanas,
  apiGrafanaDashboards,
  apiGrafanaSnapshots,
  apiGrafanaQueries
} from '@/api'

const state = () => ({
  grafanaDatasourceId: null, // Number
  isLoadingDatasourceId: false
})

const getters = {}

const actions = {
  init: ({ commit, dispatch }) => {
    dispatch('form/init')
    commit('setState', state())
  },

  deletePanel: (_, { dashboard: oriDashboard, panel, projectId } = {}) => {
    const { resourceId, maplayer_resource_uuid: maplayerResourceUuid, ...dashboard } = oriDashboard
    const formData = {
      payload: {
        dashboard: {
          ...dashboard,
          panels: dashboard.panels.filter(p => p.id !== panel.id)
        }
      },
      maplayer_resource_uuid: maplayerResourceUuid,
      resource_uuid: resourceId,
      project_uuid: projectId
    }

    return apiGrafanaDashboards.put(formData)
  },
  fetchGrafanaDatasourceId: ({ commit }, { grafanaId, projectId } = {}) => {
    if (!grafanaId) return

    commit('setState', {
      isLoadingDatasourceId: grafanaId
    })

    return apiGrafanaGrafanas.get(grafanaId, projectId)
      .then(res => {
        const grafanaDatasourceId = res?.data?.data?.datasource_id

        commit('setState', { grafanaDatasourceId })

        return grafanaDatasourceId
      })
      .finally(() => {
        commit('setState', {
          isLoadingDatasourceId: false
        })
      })
  },
  fetchSnapshotUrl: async (
    { dispatch },
    { panel, dashboard, useTeamplating = false, dashboardOptions = {} } = {}
  ) => {
    // useTeamplating為false或feature的properties(Object)
    if (!panel || !dashboard) return

    // fetch snapshotData
    const snapshotData = await dispatch('fetchSnapshotData', {
      panel,
      useTeamplating
    }).catch(error => error)
    if (!Array.isArray(snapshotData)) {
      return Promise.reject(snapshotData)
    }

    // set snapshot time range
    // from 抓所有數據最早的點
    // to 抓現在
    let from = snapshotData
      .map(data => {
        const [start] =
          data?.fields?.find?.(f => f.type === 'time')?.values || []

        return start
      })
      .filter(Boolean)
      .sort()
      .shift()
    if (!from) {
      // default from a year ago
      const aYearAgo = new Date()
      aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)

      from = +aYearAgo
    }
    // End set snapshot time range

    const formData = {
      dashboard: {
        ...dashboard,
        time: {
          from: new Date(from).toISOString(),
          to: new Date().toISOString()
        },
        panels: [
          {
            ...panel,
            snapshotData
          }
        ],
        ...dashboardOptions
      },
      panel_id: panel.id
    }

    return apiGrafanaSnapshots.post(formData).then(res => {
      const snapshotUrl = res?.data?.data?.url

      return snapshotUrl || ''
    })
  },
  fetchSnapshotData: async (
    { state },
    { panel, useTeamplating = false } = {}
  ) => {
    // useTeamplating為false或feature的properties(Object)
    if (state.grafanaDatasourceId == null) {
      return Promise.reject(new Error('Miss datasource id'))
    }

    if (!panel) return

    return apiGrafanaQueries
      .post({
        data: {
          // from: `${+new Date(dashboard.time.from)}`,
          // to: `${+new Date(dashboard.time.to)}`,
          // range: {
          //   ...dashboard.time,
          //   raw: {
          //     ...dashboard.time
          //   }
          // },
          queries: panel.targets.map(t => ({
            // query: t.query,
            query: formatQuery(panel.datasource, t, useTeamplating),
            refId: t.refId,
            datasourceId: state.grafanaDatasourceId,
            maxDataPoints: 999999999
          }))
        }
      })
      .then(res => {
        const url = res?.data?.data?.url
        const results = Object.entries(url?.results || [])

        return formatSnapshotData(results)
      })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const panels = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    form
  }
}
