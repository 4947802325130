import Axios from 'axios'
import camelize from 'camelize'
import snakeize from 'snakeize'
import { saveAs } from 'file-saver'
import { cloneDeep } from 'lodash'
import i18n from '@/i18n'

import {
  FileTree,
  Node,
  FileNode
} from '@/models'

import {
  FILE_TYPE,
  getTypeByParent,
  SETTINGS_KEYS
} from '@/models/utils'

import {
  apiS3Folders,
  apiS3Mapsets,
  apiS3Maplayers,
  apiS3MaplayerData,
  apiS3MaplayerMetadata
} from '@/api'

import {
  WsVtk2Glb
} from '@/websocket'

import { SIM_MODEL } from '@/models/Simulation/Model'
const { PARENT_VTK, BOUNDING_BOX_VTK } = SETTINGS_KEYS

export const MODEL = {
  NORMAL: 'normal',
  PROJECT: 'project',
  DASHBOARD: 'dashboard'
}

export const MAP_RES_KEY = {
  [FILE_TYPE.FOLDER]: 'folder',
  [FILE_TYPE.MAPSET]: 'mapset',
  [FILE_TYPE.FILE]: 'maplayer'
}

// roleRoot是預設沒有project或project owner的腳色權限, 打開所有權限為true
const getDefaultPermission = {
  get: function(target, name) {
    return Object.hasOwnProperty.call(target, name) ? target[name] : true
  }
}
export const roleRoot = new Proxy({}, getDefaultPermission)

const formatFileNodes = fileNodeType => files => {
  if (!Array.isArray(files)) {
    return []
  }

  const resKey = MAP_RES_KEY[fileNodeType]

  return files.map(file => {
    if (!file) {
      return null
    }

    const settings = file.settings
    if (Array.isArray(settings?.plot?.legends)) {
      // 不在db的legend參數要在這邊加回來
      settings.plot.legends.forEach(legend => {
        legend.visible = false
        legend.isPloting = false
      })
    }

    return new FileNode(file?.resource?.uuid, {
      type: fileNodeType,
      name: file[`${resKey}Name`],
      s3Uuid: file.uuid,
      createdTime: file.createdTime,
      modifiedTime: file.modifiedTime,
      size: file.size,
      settings: file.settings,
      isUploading: file.isUploading,
      isBinary: file.isBinary,
      maplayerType: file.maplayerType,
      simulationModel: file.simulationModel,
      parentMaplayerUuid: file?.parentMaplayer?.uuid
    })
  }
  )
}

export const formatLegend = (scalarsName, plotSettings) => {
  const legend = {
    scalarsName,
    visible: false, // 不要存進db
    isPloting: false, // 不要存進db
    colormapLevel: plotSettings.colormapLevel,
    colormapName: plotSettings.colormapName,
    legends: cloneDeep(plotSettings.legends),
    rangeMax: plotSettings.rangeMax,
    rangeMin: plotSettings.rangeMin
  }

  return legend
}

const apiS3 = {
  [FILE_TYPE.FOLDER]: apiS3Folders,
  [FILE_TYPE.MAPSET]: apiS3Mapsets,
  [FILE_TYPE.FILE]: apiS3Maplayers
}

const onDownloadProgress = function(progressEvent) {
  const total = parseFloat(this.size)

  if (!total || Number.isNaN(total)) {
    this.downloadProgress = null
    return
  }

  const current = progressEvent.loaded

  this.downloadProgress = Math.floor(current / total * 100)
}

const state = () => ({
  fileTree: new FileTree('s3FileTree', {
    // NOTE: root uuid應該要是s3BucketId, 但樹是runtime決定用在個人還是project
    // s3BucketId是runtime在fetchFileNodes才會決定
    root: new FileNode()
  }),
  dialog: false, // for FileManagerDialog
  asseptFileType: '.json,.geojson,.glb,.vtk,.zip,.tar',
  openNodes: [],
  activeFileNode: null,
  selectedFileNode: null,
  model: MODEL.NORMAL
})

const getters = {
  isLoadingFolders: state => state.fileTree?.root?.isLoading,
  folderNodes: state => state.fileTree?.root?.children || [],
  hasSelectedFileNode: state => state.selectedFileNode instanceof FileNode,
  isProjectModel: state => state.model === MODEL.PROJECT,
  isDashboardModel: state => state.model === MODEL.DASHBOARD,
  isFileNodeJoined: (_, getters, __, rootGetters) => fileNode => {
    if (!fileNode) {
      return false
    }

    if (getters.isDashboardModel && fileNode.type === FILE_TYPE.FILE) {
      return rootGetters['dashboards/form/isFileJoined'](fileNode)
    }

    if (getters.isProjectModel) {
      let folder = fileNode
      while (folder) {
        if (folder.type === FILE_TYPE.FOLDER) {
          break
        }

        folder = folder.parent
      }

      if (folder?.type !== FILE_TYPE.FOLDER) {
        return false
      }

      return rootGetters['projects/form/isFolderJoined'](folder)
    }

    return false
  }
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  setModel: ({ commit }, model) => {
    commit('setState', {
      model
    })
  },
  activeFileNode: ({ state, commit, dispatch }, { fileNode, open = false, fetchChildren = false, project } = {}) => {
    // open為true代表選擇節點後要幫忙打開treeview
    // fetchChildren為true代表open後要幫忙load children
    if (open && (fileNode instanceof FileNode)) {
      let openNode = fileNode
      while ((openNode instanceof FileNode)) {
        if (openNode === state.fileTree.root) {
          break
        }

        if (state.openNodes.indexOf(openNode) === -1) {
          commit('setState', {
            openNodes: [openNode, ...state.openNodes]
          })

          if (fetchChildren && openNode.type !== FILE_TYPE.FILE) {
            dispatch('fetchFileNodes', {
              project,
              parent: openNode
            })
          }
        }
        openNode = openNode.parent
      }
    }

    commit('setState', {
      activeFileNode: fileNode instanceof FileNode ? fileNode : null
    })
  },
  selectFileNode: ({ commit }, { fileNode } = {}) => {
    commit('setState', {
      selectedFileNode: fileNode instanceof FileNode ? fileNode : null
    })
  },
  fetchFileNodes: async (
    { rootState, state, commit, dispatch },
    { project, parent, loading = true, ...queryParams } = {}
  ) => {
    // 不指定parent就是fetch root的children
    // parent可傳root進來, 方便使用的人邏輯統一

    // 若不指定parent則預設為root
    const parentNode = ((parent instanceof FileNode) && parent) ||
      state.fileTree.root

    parentNode.setProperties({
      isLoading: loading
    })
    if (!project && !rootState.s3BucketId) {
      const error = await dispatch('fetchResourceIds', null, {
        root: true
      }).catch(error => error)

      if (!rootState.s3BucketId) {
        parentNode.setProperties({
          isLoading: false
        })

        return Promise.reject(error)
      }
    }
    // TRICKY: 將root uuid設為s3BucketId
    state.fileTree.root.setProperties({
      uuid: project?.s3BucketId || rootState.s3BucketId
    })

    const targetFileType = getTypeByParent[parentNode?.type]
    const params = {
      parent_resource_uuid: parentNode?.uuid,
      ...snakeize(queryParams)
    }
    if (project) {
      params.project_uuid = project.uuid
    }
    return apiS3[targetFileType].get(params)
      .then(res => {
        // const resKey = MAP_RES_KEY[targetFileType]
        const files = res.data.data

        if (!Array.isArray(files)) {
          return res
        }

        const fileNodes = formatFileNodes(targetFileType)(camelize(files))

        // 添加尚未在樹上的node
        fileNodes.forEach(node => {
          const existNode = state.fileTree.findNodeBF(parentNode, node.uuid)

          if (existNode) {
            existNode.readSettings(node.settings)
            existNode.setProperties({
              name: node.name,
              modifiedTime: node.modifiedTime,
              isUploading: node.isUploading,
              isBinary: node.isBinary
            })
          }

          if (!existNode) {
            commit('addNode', {
              parent: parentNode,
              node
            })
          }
        })

        // 去除已經被刪除的node
        // 避免遍例array同時操作array
        const removeNodes = []
        parentNode.children.forEach(node => {
          if (fileNodes.every(newNode => newNode.uuid !== node.uuid)) {
            removeNodes.push(node)
          }
        })
        removeNodes.forEach(node => {
          if (parentNode === state.activeFileNode) {
            commit('setState', {
              activeFileNode: null
            })
          }
          commit('removeNode', node)
        })

        parentNode.setProperties({
          isLoading: false
        })

        return fileNodes
      })
      .catch(error => {
        if (parentNode === state.activeFileNode) {
          commit('setState', {
            activeFileNode: null
          })
        }
        if (parentNode === state.selectedFileNode) {
          commit('setState', {
            selectedFileNode: parentNode.parent
          })
        }

        if (
          parentNode.parent && // root node的error不用特別處理
           error?.response?.status === 404 // 找不到指定資料夾檔案
        ) {
          if (!rootState.snackbar.snack) {
            dispatch('snackbar/showWarning', {
              content: '資料夾已經不存在'
            }, {
              root: true
            })
          }
          // 當資料夾不存在的時候幫使用者遞迴到可以撈到資料的那層或root為止
          // 不要show error
          return dispatch('fetchFileNodes', {
            project,
            parent: parentNode.parent
          })
        }

        parentNode.setProperties({
          isLoading: i18n.t('load_faild')
        })

        return Promise.reject(error)
      })
      .finally(() => {
        parentNode.setProperties({
          hasLoaded: true
        })
      })
  },
  fetchFileSubTree: ({ state, commit, dispatch }, { project, fileUuid } = {}) => {
    if (!fileUuid) {
      return
    }
    const params = {
      resource_uuid: fileUuid
    }
    if (project) {
      params.project_uuid = project.uuid
    }
    return apiS3[FILE_TYPE.FILE].get(params)
      .then(res => {
        const [maplayer] = camelize(res.data?.data)
        const [fileNode] = formatFileNodes(FILE_TYPE.FILE)([maplayer])
        const [mapsetNode] = formatFileNodes(FILE_TYPE.MAPSET)([maplayer?.mapset])
        const [folderNode] = formatFileNodes(FILE_TYPE.FOLDER)([maplayer?.mapset?.folder])

        const targetFolderNode = state.fileTree.getOrAddChildren({
          parent: state.fileTree.root,
          node: folderNode,
          beforeNode: state.fileTree.root.children[0]
        })
        const targetMapsetNode = state.fileTree.getOrAddChildren({
          parent: targetFolderNode,
          node: mapsetNode,
          beforeNode: targetFolderNode.children[0]
        })
        const targetFileNode = state.fileTree.getOrAddChildren({
          parent: targetMapsetNode,
          node: fileNode,
          beforeNode: targetMapsetNode.children[0]
        })

        // v-treeview因為children有東西, 所以open後不會幫忙load children
        dispatch('activeFileNode', {
          fileNode: targetFileNode,
          open: true,
          fetchChildren: true,
          project
        })
        dispatch('selectFileNode', {
          fileNode: targetMapsetNode
        })

        return targetFileNode
      })
  },
  addFolder: ({ rootState, state, commit }, { name, parent, project } = {}) => {
    // 不指定parent就是fetch root的children
    // parent可傳root進來, 方便使用的人邏輯統一
    // 若parent為root則parentNode需為不指定
    const parentNode = (parent instanceof FileNode) &&
      parent !== state.fileTree.root &&
      parent

    const bucketId = project?.s3BucketId || rootState.s3BucketId
    const parentResourceUuid = parentNode?.uuid || bucketId

    const fileNodeType = getTypeByParent[parentNode?.type]
    const filedKey = MAP_RES_KEY[fileNodeType]

    const formData = {
      parent_resource_uuid: parentResourceUuid,
      [`${filedKey}_name`]: name
    }
    if (project) {
      formData.project_uuid = project.uuid
    }

    return apiS3[fileNodeType].post(formData)
      .then(res => {
        const result = camelize(res.data.data)
        const [fileNode] = formatFileNodes(fileNodeType)([result])

        if (fileNode instanceof FileNode) {
          commit('addNode', {
            parent: parent || state.fileTree.root,
            node: fileNode
          })

          return fileNode
        }
      })
  },
  deleteFile: ({ state, commit }, { fileNode, project }) => {
    if (!(fileNode instanceof FileNode)) {
      return Promise.resolve()
    }

    const formData = {
      resource_uuid: fileNode.uuid
    }
    if (project) {
      formData.project_uuid = project.uuid
    }
    return apiS3[fileNode.type].delete(formData)
      .then(() => {
        if (fileNode === state.activeFileNode) {
          commit('setState', {
            activeFileNode: fileNode.parent
          })
        }
        if (fileNode === state.selectedFileNode) {
          commit('setState', {
            selectedFileNode: fileNode.parent
          })
        }
        commit('removeNode', fileNode)
      })
  },
  renameFileNode: (_, { fileNode, project, name }) => {
    if (!(fileNode instanceof FileNode) || !name) {
      return Promise.resolve()
    }

    const filedKey = MAP_RES_KEY[fileNode.type]
    const data = {
      resource_uuid: fileNode.uuid,
      [`${filedKey}_name`]: name
    }
    if (project) {
      data.project_uuid = project.uuid
    }
    return apiS3[fileNode.type].patch(data)
      .then(() => {
        fileNode.setProperties({
          name
        })
      })
  },
  createMaplayer: ({ commit, dispatch }, { project, parent, maplayerName, updateProjectPermissions = true, settings = {}, ...postData } = {}) => {
    if (
      !(parent instanceof Node) ||
      !maplayerName
    ) {
      return
    }

    return apiS3Maplayers.post({
      project_uuid: project?.uuid,
      parent_resource_uuid: parent.uuid,
      maplayer_name: maplayerName,
      settings,
      ...snakeize(postData)
    }).then(res => {
      const fileNodeType = FILE_TYPE.FILE
      const data = camelize(res.data.data)
      const [fileNode] = formatFileNodes(fileNodeType)([data])

      if (!fileNode) {
        return Promise.reject(new Error('資料格式錯誤'))
      }

      commit('addNode', {
        parent,
        node: fileNode
      })

      if (project && updateProjectPermissions) {
        dispatch('projects/fetchPermissions', {
          projectId: project.uuid
        }, { root: true })
      }

      return fileNode
    })
  },
  uploadFile: ({ dispatch }, { project, parent, file, settings, onUploadProgress } = {}) => {
    if (!(parent instanceof FileNode)) {
      console.debug('Cannot upload file w/o target parent')
      return
    }
    if (!(file instanceof File)) {
      console.debug('Cannot upload file w/o File instance')
      return
    }

    let newFileNode
    return dispatch('createMaplayer', {
      project,
      parent,
      maplayerName: file.name,
      settings,
      updateProjectPermissions: false
    }).then(fileNode => {
      if (!(fileNode instanceof FileNode)) {
        return Promise.reject(new Error('未知錯誤'))
      }
      newFileNode = fileNode
      // commit('addNode', {
      //   parent: parent,
      //   node: fileNode
      // })

      newFileNode.setProperties({
        isUploading: true,
        isBinary: false
      })

      return dispatch('updateMaplayerData', {
        project,
        fileNode,
        blob: file,
        onUploadProgress
      })
        .then(res => {
          if (fileNode.isVtkFile) {
            const scalarsNames = res.data?.data?.array_names || []

            if (Array.isArray(scalarsNames)) {
              const newSettings = {
                ...fileNode.settings,
                plot: {
                  ...(fileNode.settings?.plot || {}),
                  scalarsNames
                }
              }

              dispatch('updateFileSettings', {
                project,
                fileNode,
                settings: newSettings
              })
                .catch(error => {
                  fileNode.setProperties({
                    isUploading: false,
                    isBinary: false
                  })

                  dispatch('snackbar/showError', {
                    content: error
                  }, { root: true })
                })
            }
          }
        })
    })
      .then(() => {
        newFileNode.setProperties({
          isUploading: false,
          isBinary: true
        })
      })
      .catch(error => {
        // 取消上傳的話error為undefined
        if (Axios.isCancel(error)) {
          dispatch('deleteFile', {
            fileNode: newFileNode,
            project
          })

          return Promise.reject(error)
        }
        if (newFileNode) {
          dispatch('deleteFile', {
            fileNode: newFileNode,
            project
          })
        }

        return Promise.reject(error)
      })
  },
  updateMaplayerData: (_, { project, fileNode, blob, jsonContent, onUploadProgress } = {}) => {
    if (
      (!blob && !jsonContent) ||
      !fileNode?.uuid
    ) {
      return Promise.reject(new Error(i18n.t('api_errors.data_error')))
    }

    let maplayerBlob = blob
    if (!blob && jsonContent) {
      const jsonse = JSON.stringify(jsonContent, null, 2)
      maplayerBlob = new Blob([jsonse], { type: 'application/json' })
    }

    const formData = new FormData()
    formData.append('resource_uuid', fileNode.uuid)
    formData.append('maplayer', maplayerBlob)
    if (project) {
      formData.append('project_uuid', project.uuid)
    }
    return apiS3MaplayerData.put(formData, onUploadProgress)
      .then(res => {
        if (!blob && jsonContent) {
          fileNode.setFileContent(jsonContent)
        }

        return res
      })
  },
  downloadFile: async ({ dispatch }, { project, fileNode } = {}) => {
    if (!(fileNode instanceof FileNode)) {
      return Promise.resolve()
    }

    fileNode.setProperties({
      isDownloading: true
    })

    const params = {
      project_uuid: project?.uuid,
      resource_uuid: fileNode.uuid
    }
    if (!fileNode.size) {
      await dispatch('fetchFileMetadata', { project, fileNode }).catch(() => {})
    }
    fileNode.downloadProgress = 0
    return apiS3MaplayerData.get(params, 'blob', onDownloadProgress.bind(fileNode))
      .then(res => {
        const blob = new Blob([res.data])

        saveAs(blob, fileNode.name || `${fileNode.uuid}`)

        return res
      })
      .finally(() => {
        fileNode.downloadProgress = 0
        fileNode.setProperties({
          isDownloading: false
        })
      })
  },
  cancelDownloadFile: () => {
    return apiS3MaplayerData.cancelDownload()
  },
  fetchFileContent: async ({ dispatch }, { project, fileNode } = {}) => {
    if (!(fileNode instanceof FileNode)) {
      return Promise.resolve()
    }

    fileNode.setProperties({
      isLoading: true
    })

    // NOTE: catch file content 機制
    // 尚無 refresh catch 機制
    if (fileNode.fileContent) {
      fileNode.setProperties({
        isLoading: false
      })

      return Promise.resolve(fileNode)
    }

    // 取得 size
    if (!fileNode.size) {
      await dispatch('fetchFileMetadata', { project, fileNode }).catch(() => {})
    }
    // 確認3d檔案有設定3d-center和sky-axis
    const error = fileNode.checkFileSettings()
    if (error) {
      return Promise.reject(error)
    }

    const params = {
      project_uuid: project?.uuid,
      resource_uuid: fileNode.uuid
    }
    const responseType = fileNode.is3DFile
      ? 'blob'
      : 'json'
    return apiS3MaplayerData.get(params, responseType, onDownloadProgress.bind(fileNode))
      .then(res => {
        fileNode.setProperties({
          isLoading: false
        })

        if (fileNode.is3DFile) {
          const blob = new Blob([res.data])
          fileNode.setFileContent(blob)
        } else {
          const geoJson = res.data

          if (!geoJson) {
            return Promise.reject(new Error(i18n.t('api_errors.data_error')))
          }

          fileNode.setFileContent(geoJson)
        }

        return fileNode
      })
      .catch(error => {
        fileNode.setProperties({
          isLoading: i18n.t('load_faild')
        })

        return Promise.reject(error)
      })
      .finally(() => {
        fileNode.downloadProgress = 0
      })
  },
  removeFileLoaded: ({ state }, { node } = {}) => {
    // 給map的layerTree在removeNode時更新fileNode狀態使用的
    // 1. 根據輸入的node找出fileTree上對應的fileNode
    // 2. 取fileNode下(含自己)所有file類型的fileNodes
    // 3. 更新file類型的fileNodes狀態

    if (!(node instanceof Node)) {
      return
    }

    const fileNode = state.fileTree.findNodeDF(state.fileTree.root, node.uuid)

    if (!fileNode) {
      return
    }

    state.fileTree
      .getLeavesDF(fileNode, FileNode)
      .filter(fileNode => fileNode.type === FILE_TYPE.FILE)
      .forEach(fileNode => fileNode.setProperties({ hasLoaded: false }))
  },
  fetchFileMetadata: (_, { project, fileNode } = {}) => {
    // S3紀錄的metadata, 檔案大小(size)也是從s3來

    if (!(fileNode instanceof FileNode)) {
      return
    }

    fileNode.setProperties({
      isLoadingMetadata: true
    })
    return apiS3MaplayerMetadata.get({
      project_uuid: project?.uuid,
      resource_uuid: fileNode.uuid
    })
      .then(res => {
        const s3Metadata = res.data?.metadata

        fileNode.setSize(s3Metadata?.ContentLength)
      })
      .finally(() => {
        fileNode.setProperties({
          isLoadingMetadata: false
        })
      })
  },
  updateMaplayer: (_, { project, fileNode, data } = {}) => {
    if (!(fileNode instanceof FileNode)) {
      return
    }

    const resourceUuid = fileNode.uuid
    const apiData = {
      resource_uuid: resourceUuid,
      ...data
    }
    if (project) {
      apiData.project_uuid = project.uuid
    }

    return apiS3Maplayers.patch(apiData)
  },
  updateFileSettings: ({ dispatch }, { project, fileNode, settings: baseSettings } = {}) => {
    if (!(fileNode instanceof FileNode) || !baseSettings) {
      return
    }

    // fileNode.setProperties({
    //   isLoadingMetadata: true
    // })

    const settings = cloneDeep(baseSettings)
    const legends = settings?.plot?.legends
    if (Array.isArray(legends)) {
      legends.forEach(legend => {
        delete legend.visible
        delete legend.isPloting
      })
    }

    const data = {
      settings
    }
    return dispatch('updateMaplayer', { project, fileNode, data })
      .then(() => {
        fileNode.readSettings(data.settings)
      })
      // .finally(() => {
      //   fileNode.setProperties({
      //     isLoadingMetadata: false
      //   })
      // })
  },
  createGlbFile: async ({ state, commit, dispatch }, { project, fileNode, loading = true, updateProjectPermissions = true, glbFileName = null, activeScalarsName = null, rangeMin = null, rangeMax = null } = {}) => {
    if (!fileNode.isVtkFile) {
      return
    }

    // 確認3d檔案有設定3d-center和sky-axis
    const error = fileNode.checkFileSettings()
    if (error) {
      return Promise.reject(error)
    }

    if (loading) {
      fileNode.setProperties({
        isLoading: true
      })
    }
    const maplayerName = glbFileName || `${fileNode.name.split('.').slice(0, -1).join('.')}.glb`
    let glbFileNode = state.fileTree.findNodeBF(fileNode.parent, maplayerName, 'name')
    if (glbFileNode) {
      // update glb file settings by vtk file settings
      glbFileNode.readSettings({
        ...glbFileNode.settings,
        ...fileNode.settings,
        [PARENT_VTK.key]: fileNode.uuid,
        plot: glbFileNode.settings?.plot || {}
      })

      glbFileNode.setFileContent(undefined)
      fileNode.setProperties({
        hasLoaded: false
      })
    }
    // 建立glb的空maplayer
    if (!glbFileNode) {
      glbFileNode = await dispatch('createMaplayer', {
        project,
        updateProjectPermissions,
        parent: fileNode.parent,
        maplayerName,
        simulationModelUuid: fileNode.simulationModel,
        settings: {
          ...(fileNode.settings || {}),
          [PARENT_VTK.key]: fileNode.uuid
        }
      })
        .catch(error => {
          if (loading) {
            fileNode.setProperties({
              isLoading: i18n.t('load_faild')
            })
          }

          return Promise.reject(error)
        })

      if (glbFileNode instanceof Error) {
        return Promise.reject(glbFileNode)
      }
    }
    // 使用websocket建立vtk2glb job
    const wsVtk2Glb = new WsVtk2Glb()
    wsVtk2Glb.start({
      project_uuid: project.uuid,
      parent_resource_uuid: fileNode.parent.uuid, // mapset resource uuid
      vtk_mesh_pairs: [
        {
          mesh_uuid: '',
          vtk_resource_uuid: fileNode.uuid
        }
      ],
      glb_resource_uuid: glbFileNode.uuid,
      bounding_box_vtk_resource_uuid: fileNode.settings[BOUNDING_BOX_VTK.key],
      active_scalars_name: activeScalarsName,
      range_min: rangeMin,
      range_max: rangeMax
    })
    if (loading) {
      fileNode.setProperties({
        isLoading: true
      })
    }
    glbFileNode.setProperties({
      isLoading: true,
      isUploading: true
    })
    return new Promise((resolve, reject) => {
      let newSettings
      wsVtk2Glb.$on(wsVtk2Glb.EVENT.ENDED, async () => {
        wsVtk2Glb.close()

        await dispatch('updateFileSettings', {
          project,
          fileNode: glbFileNode,
          settings: newSettings
        })

        Promise.all([
          // update files
          dispatch('fetchFileNodes', {
            project,
            parent: fileNode.parent,
            loading: false
          }),
          // update new file permission
          updateProjectPermissions && dispatch('projects/fetchPermissions', {
            projectId: project.uuid
          }, { root: true })
        ].filter(Boolean))
          .then(() => {
            resolve(
              dispatch('fetchFileContent', {
                project,
                fileNode: glbFileNode
              })
                .then(fileNodeWithContent => {
                  if (loading) {
                    fileNode.setProperties({
                      isLoading: false
                    })
                  }

                  return fileNodeWithContent
                })
            )
          })
          .catch(error => {
            if (loading) {
              fileNode.setProperties({
                isLoading: false
              })
            }
            glbFileNode.setProperties({
              isLoading: i18n.t('load_faild'),
              isUploading: false
            })

            reject(error)
          })
      })

      wsVtk2Glb.$on(wsVtk2Glb.TASK.SUCCESS, data => {
        const oriPlotSettings = glbFileNode.settings?.plot || {}
        const plotSettings = data?.plotSettings?.[0] || {}
        const meshes = plotSettings.vtkMeshPairs || []
        const scalarsNames = oriPlotSettings?.scalarsNames || []
        const activeScalarsName = plotSettings.activeScalarsName

        const legends = scalarsNames.map(scalarsName => {
          const legend = formatLegend(scalarsName, plotSettings)

          if (scalarsName !== activeScalarsName) {
            delete legend.legends
            delete legend.rangeMin
            delete legend.rangeMax
          }

          return legend
        })

        newSettings = {
          ...glbFileNode.settings,
          plot: {
            ...oriPlotSettings,
            legends
          },
          meshes: meshes.map(mesh => ({
            name: mesh.meshUuid,
            [PARENT_VTK.key]: fileNode.uuid,
            activeScalarsName,
            legends: mesh?.scalars || {}
          }))
        }
      })

      wsVtk2Glb.$on(wsVtk2Glb.EVENT.ERROR, error => {
        if (loading) {
          fileNode.setProperties({
            isLoading: i18n.t('load_faild')
          })
        }
        glbFileNode.setProperties({
          isLoading: i18n.t('load_faild'),
          isUploading: false
        })

        reject(error)
      })
    })
  },
  createMassbalanceGlbFiles: async ({ state, dispatch }, { project, vtkFileNode, months, rangeMin = null, rangeMax = null } = {}) => {
    if (
      !Array.isArray(months) ||
      !vtkFileNode?.isVtkFile
    ) {
      return
    }

    // 確認3d檔案有設定3d-center和sky-axis
    const error = vtkFileNode.checkFileSettings()
    if (error) {
      return Promise.reject(error)
    }

    vtkFileNode.setProperties({
      isLoading: true
    })

    // 以下只紀錄成功創建maplayer的glb和months
    const failedMonths = []
    const glbFileNodes = await Promise.all(
      months.map(month => {
        const maplayerName = `${vtkFileNode.name.split('.')[0]}_${month}.glb`
        const glbFileNode = state.fileTree.findNodeBF(vtkFileNode.parent, maplayerName, 'name')
        if (glbFileNode) {
          // update glb file settings by vtk file settings
          glbFileNode.readSettings({
            ...glbFileNode.settings,
            ...vtkFileNode.settings,
            [PARENT_VTK.key]: vtkFileNode.uuid,
            plot: glbFileNode.settings?.plot || {}
          })

          glbFileNode.setFileContent(undefined)
          vtkFileNode.setProperties({
            hasLoaded: false
          })

          return Promise.resolve(glbFileNode)
        }

        // 建立glb的空maplayer
        return dispatch('createMaplayer', {
          project,
          updateProjectPermissions: false,
          parent: vtkFileNode.parent,
          maplayerName,
          simulationModelUuid: SIM_MODEL.MASSBALANCE, // 讓glb可以做水平衡畫線
          settings: {
            ...(vtkFileNode.settings || {}),
            [PARENT_VTK.key]: vtkFileNode.uuid,
            plot: {
              ...(vtkFileNode.settings?.plot || {}),
              scalarsNames: [month]
            }
          }
        })
          .catch(() => {
            failedMonths.push(month)
            return Promise.resolve(null)
          })
      })
    )
      .then(glbFileNodes => glbFileNodes.filter(Boolean))
      .catch(error => {
        vtkFileNode.setProperties({
          isLoading: i18n.t('load_faild')
        })

        return Promise.reject(error)
      })

    const successMonths = months.filter(month => failedMonths.indexOf(month) === -1)

    dispatch('projects/fetchPermissions', {
      projectId: project.uuid
    }, { root: true })

    // 使用websocket建立vtk2glb job
    const wsVtk2Glb = new WsVtk2Glb()
    let iGlbFileNode = 0
    let glbFileNode = glbFileNodes[iGlbFileNode]
    let activeScalarsName = successMonths[iGlbFileNode]

    const total = glbFileNodes.length
    wsVtk2Glb.start({
      project_uuid: project.uuid,
      parent_resource_uuid: vtkFileNode.parent.uuid, // mapset resource uuid
      vtk_mesh_pairs: [
        {
          mesh_uuid: '',
          vtk_resource_uuid: vtkFileNode.uuid
        }
      ],
      glb_resource_uuid: glbFileNode.uuid,
      bounding_box_vtk_resource_uuid: vtkFileNode.settings[BOUNDING_BOX_VTK.key],
      active_scalars_name: activeScalarsName,
      range_min: rangeMin,
      range_max: rangeMax
    })

    glbFileNode.setProperties({
      isLoading: true,
      isUploading: true
    })
    return new Promise((resolve, reject) => {
      let newSettings
      wsVtk2Glb.$on(wsVtk2Glb.EVENT.ENDED, async () => {
        await Promise.all([
          dispatch('updateFileSettings', {
            project,
            fileNode: glbFileNode,
            settings: newSettings
          }),
          dispatch('fetchFileNodes', {
            project,
            parent: glbFileNode.parent,
            loading: false
          })
        ])

        // update files state
        await dispatch('fetchFileContent', {
          project,
          fileNode: glbFileNode
        })
          .then(fileNodeWithContent => {
            glbFileNode.setProperties({
              isLoading: false
            })

            return fileNodeWithContent
          })
          .catch(() => {
            glbFileNode.setProperties({
              isLoading: i18n.t('load_faild'),
              isUploading: false
            })
          })

        iGlbFileNode++
        if (iGlbFileNode >= total) {
          wsVtk2Glb.close()

          vtkFileNode.setProperties({
            isLoading: false
          })

          resolve(glbFileNodes)

          return
        }
        glbFileNode = glbFileNodes[iGlbFileNode]
        activeScalarsName = successMonths[iGlbFileNode]

        glbFileNode.setProperties({
          isLoading: true,
          isUploading: true
        })
        wsVtk2Glb.start({
          project_uuid: project.uuid,
          parent_resource_uuid: vtkFileNode.parent.uuid, // mapset resource uuid
          vtk_mesh_pairs: [
            {
              mesh_uuid: '',
              vtk_resource_uuid: vtkFileNode.uuid
            }
          ],
          glb_resource_uuid: glbFileNode.uuid,
          bounding_box_vtk_resource_uuid: vtkFileNode.settings[BOUNDING_BOX_VTK.key],
          active_scalars_name: activeScalarsName,
          range_min: rangeMin,
          range_max: rangeMax
        })
      })

      wsVtk2Glb.$on(wsVtk2Glb.TASK.SUCCESS, data => {
        const oriPlotSettings = glbFileNode.settings?.plot || {}
        const plotSettings = data?.plotSettings?.[0] || {}
        const meshes = plotSettings.vtkMeshPairs || []
        const scalarsNames = oriPlotSettings?.scalarsNames || []
        const activeScalarsName = plotSettings.activeScalarsName

        const legends = scalarsNames.map(scalarsName => {
          const legend = formatLegend(scalarsName, plotSettings)

          if (scalarsName !== activeScalarsName) {
            delete legend.legends
            delete legend.rangeMin
            delete legend.rangeMax
          }

          return legend
        })

        newSettings = {
          ...glbFileNode.settings,
          plot: {
            ...oriPlotSettings,
            legends
          },
          meshes: meshes.map(mesh => ({
            name: mesh.meshUuid,
            [PARENT_VTK.key]: vtkFileNode.uuid,
            activeScalarsName,
            legends: mesh?.scalars || {}
          }))
        }
      })

      wsVtk2Glb.$on(wsVtk2Glb.EVENT.ERROR, error => {
        vtkFileNode.setProperties({
          isLoading: i18n.t('load_faild')
        })

        glbFileNode.setProperties({
          isLoading: i18n.t('load_faild'),
          isUploading: false
        })

        reject(error)
      })
    })
  }

}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  addNode: (state, { parent, node, beforeNode } = {}) => {
    state.fileTree.addNode(parent, node, beforeNode)
  },
  removeNode: (state, node) => {
    state.fileTree.removeNode(node)
  }
}

export const files = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
