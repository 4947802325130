import {
  LayerTree,
  Node,
  FileNode,
  LayerNode,
  Layer3DNode
} from '@/models'

import { Map } from 'mapbox-gl'

import * as modules from './modules'

export const MAP_PAINT_TYPE_DRAW_TYPE = {
  circle: 'point',
  line: 'lineString',
  fill: 'polygon'
}

const state = () => ({
  layerTree: null,
  currentFolderNode: null,
  currentLayerNode: null,
  expansionMapsets: [],
  zoom: 8,
  // zoom: 14,
  center: [121, 24],
  pitch: 0,
  animationFolder: null
})

const getters = {
  map: state => {
    return state.layerTree?.map
  },
  folderNodes: state => state.layerTree?.root?.children || [],
  layerNodes: state => state.layerTree?.getLeavesDF(state.layerTree?.root, LayerNode) || [],
  currentGlbMeshes: state => {
    if (!(state.currentLayerNode instanceof Layer3DNode)) {
      return []
    }

    return state.currentLayerNode.meshes
  },
  isMapModelByRoute: _ => route => {
    return route.name === 'Map'
  },
  isLiteModelByRoute: _ => route => {
    return route.meta.liteModel
  },
  isPostprocessModelByRoute: _ => route => {
    return route.name === 'MapPostprocess'
  },
  isDrawerModelByRoute: _ => route => {
    return route.name === 'MapDrawer'
  },
  isAnimationPlaying: state => !!state.animationFolder
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  initLayerTree: ({ commit }, { map } = {}) => {
    if (!(map instanceof Map)) {
      console.debug('Cannot create LayerTree w/o Map instance')
      return
    };

    const layerTree = new LayerTree('mapLayerTree', {
      map,
      root: new Node('mapLayerTreeRoot')
    })

    commit('setState', { layerTree })
  },
  initMap: ({ state, getters }) => {
    if (!getters.map) return Promise.resolve()

    /* eslint-disable */
    getters.map?.setZoom(state.zoom)
    getters.map?.setCenter(state.center)
    getters.map?.setPitch(state.pitch)
    getters.map?.rotateTo(0)
    /* eslint-enable */
  },
  addLayer: ({ commit, state }, { fileNode, beforeNode, fitBounds = true } = {}) => {
    if (!(fileNode instanceof FileNode) || !fileNode.fileContent) {
      return
    }

    if (!fileNode.fileContent) {
      console.debug(`Cannot find file content: ${fileNode}`)

      return
    }

    const targetMapset = fileNode.parent
    const targetFolder = targetMapset?.parent

    if (!(targetMapset instanceof FileNode) || !(targetFolder instanceof FileNode)) {
      return
    }

    // check folder & mapset
    const folder = state.layerTree.getOrAddChildren({
      parent: state.layerTree.root,
      node: new LayerNode(targetFolder?.uuid, targetFolder)
    })
    const mapset = state.layerTree.getOrAddChildren({
      parent: folder,
      node: new LayerNode(targetMapset?.uuid, targetMapset)
    })

    // NOTE: 重複layerNode case
    // vtk to glb會覆蓋原本glb, 此時需要重新載入
    // 之後若有case不想重新載入, 要另外想辦法
    const existLayerNode = state.layerTree.findNodeBF(mapset, fileNode.uuid)
    let existBeforeNode
    if (existLayerNode) {
      // return Promise.reject(fileNode)
      existBeforeNode = existLayerNode.parent.children[existLayerNode.index + 1]
      commit('removeNode', existLayerNode)
    }

    // add layer to mapset
    const layerNode = fileNode.is3DFile
      ? new Layer3DNode(fileNode.uuid, fileNode.toLayerNodeFormate())
      : new LayerNode(fileNode.uuid, fileNode.toLayerNodeFormate())

    commit('addNode', {
      parent: mapset,
      node: layerNode,
      beforeNode: beforeNode || existBeforeNode,
      fitBounds
    })

    commit('setState', {
      currentFolderNode: folder,
      currentLayerNode: layerNode
    })

    // 將來源的fileNode設loaded
    fileNode.setProperties && fileNode.setProperties({
      hasLoaded: true
    })

    return layerNode
  },
  removeNode: async ({ state, commit, dispatch }, { node } = {}) => {
    const curFolder = state.currentFolderNode
    const curLayer = state.currentLayerNode
    commit('setState', {
      currentFolderNode: node === curFolder
        ? null
        : curFolder,
      currentLayerNode: node === curLayer
        ? null
        : node === curLayer?.folderNode
          ? null
          : curLayer
    })

    await dispatch('files/removeFileLoaded', {
      node
    }, { root: true })

    commit('removeNode', node)
  },
  resetEditSimParamFileNode: ({ getters }) => {
    const layerNodes = getters.layerNodes

    layerNodes.forEach(layerNode => {
      layerNode.setProperties({
        editSimParamFileNode: null
      })
    })
  },
  moveNode: ({ commit }, { node, beforeNode } = {}) => {
    commit('moveNode', { node, beforeNode })
  },
  toggleVisible: ({ commit }, { node } = {}) => {
    commit('toggleVisible', node)
  }
}

const mutations = {
  setState: (state, payload) => {
    Object.assign(state, payload)
  },
  addNode: (state, { parent, node, beforeNode, fitBounds = true } = {}) => {
    state.layerTree.addNode(parent, node, beforeNode, fitBounds)
  },
  removeNode: (state, node) => {
    state.layerTree.removeNode(node)
  },
  moveNode: (state, { node, beforeNode }) => {
    state.layerTree.moveNode(node, beforeNode)
  },
  toggleVisible: (state, node) => {
    state.layerTree.toggleVisible(node)
  }
}

export const map = {
  namespaced: true,
  modules,
  getters,
  state,
  mutations,
  actions
}
