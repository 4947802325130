// import { createTag } from './tag'
// import { formatTagTemplate } from './tag'

export const createTarget = (alias = '') => {
  return {
    refId: alias,
    measurement: '',
    query: '',
    select: [
      [
        {
          params: [''],
          type: 'field'
        },
        {
          params: [],
          type: 'mean'
        }
      ]
    ],
    tags: []
    // tags: [createTag()]
    // groupBy: [
    //   {
    //     params: ['$__interval'],
    //     type: 'time'
    //   },
    //   {
    //     params: ['null'],
    //     type: 'fill'
    //   }
    // ],
    // orderByTime: 'ASC',
    // policy: 'default',
    // queryType: 'randomWalk',
    // resultFormat: 'time_series',
  }
}

export const formatQuery = (
  influxBucketName,
  target,
  useTeamplating = false
) => {
  // useTeamplating為false或feature的properties(Object)
  const el = '\r\n  '
  const from = `from(bucket:"${influxBucketName}")${el}`
  const range = `|> range(start:-20y)${el}`

  // filter tags
  const queryTag = target.tags
    .filter(tag => tag.key)
    .filter(tag => !!useTeamplating || !tag.isTemplate)
    .map(tag => {
      // const tagValue = tag.isTemplate ? formatTagTemplate(tag) : tag.value
      const tagValue = tag.isTemplate
        ? useTeamplating[tag.key] || ''
        : tag.value

      return `r.${tag.key} ${tag.operator} "${tagValue}"`
    })
    // .map(tag => `r.${tag.key} ${tag.operator} "\${${tag.key}}"`)
    .reduce((acc, tag) => {
      acc += `and${el}    ${tag}`

      return acc
    }, '')
  // filter field
  const selectField = target?.select?.[0]?.[0]?.params?.[0] || ''
  const queryField = `and${el}    r._field == ${
    selectField ? `"${selectField}"` : 'r._field'
  }`

  const filter = `|> filter(fn:(r) =>${el}    r._measurement == "${target.measurement}" ${queryTag} ${queryField})`

  return `${from}    ${range}    ${filter}`
}
