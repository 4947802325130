import { WsInterpolating } from '@/websocket'

const state = () => ({
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  start: ({ rootGetters }, { project, points, range, params } = {}) => {
    const wsInterpolating = new WsInterpolating()

    return new Promise((resolve, reject) => {
      wsInterpolating.$on(wsInterpolating.TASK.SUCCESS, data => {
        resolve(data)
      })

      wsInterpolating.$on(wsInterpolating.EVENT.ERROR, error => {
        reject(error)
      })

      const [image] = rootGetters['simulation/model']?.images?.slice?.(-1) || []

      wsInterpolating.start({
        image: image.imageName,
        task_data: {
          input: {
            geojson_list: [
              {
                geo_file: points.uuid,
                param_file: params.uuid
              },
              {
                geo_file: range.uuid,
                param_file: ''
              }
            ]
          }
        },
        project_uuid: project.uuid
      })
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const interpolating = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
