// import { base64StringToArrowTable, arrowTableToDataFrame } from '@grafana/data'

export const formatSnapshotData = queryResults => {
  // queryResults 是每個Target進行query後得到data的結果
  // 每個target query會得到n個Fields, dataframe的fileds僅代表Time和某Field
  // 最後攤平所有target query得到的Fields成一個Array Object就是snapshotData
  if (!Array.isArray(queryResults)) return []

  return queryResults.flatMap(([refId, qResult]) => {
    return qResult.frames
      // .map(dataframeB64String =>
      //   arrowTableToDataFrame(base64StringToArrowTable(dataframeB64String))
      // )
      .flatMap(frame => {
        frame.refId = refId
        return {
          refId: refId,
          fields: frame.schema.fields.map((field, iField) => ({
            ...field,
            // values: Object.values(field.values.toArray())
            values: frame.data.values[iField]
          })),
          meta: frame.schema.meta,
          name: frame.schema.name
        }
      })
  })
}
